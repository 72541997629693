html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

body {
  min-height: 100vh;
}

/* a {
  color: #b76e35;
  text-decoration: none;
}

a:not(.MuiButton-root, .MuiButtonBase-root):hover, a:not(.MuiButton-root, .MuiButtonBase-root):active {
  color: #b76e35;
  text-decoration: underline;
} */

* {
  box-sizing: border-box;
}

ul {
  padding-left: 1.25em;
  margin: 0;
}

.grecaptcha-badge {
  left: -99999px;
  opacity: 0;
}
