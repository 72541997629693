.pinpointer {
  animation-delay: 0.5s;
  animation-direction: alternate;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: pinpointer;
  animation-timing-function: ease-in-out;
  font-size: 4rem;
  margin-bottom: 2rem;
}

@keyframes pinpointer {
  from {
    margin-left: -8rem;
    rotate: -30deg;
  }

  to {
    margin-left: 8rem;
    rotate: 30deg;
  }
}
